import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import Preview from '../../components/Preview'
import Tablet from '../../components/Preview/Tablet'
import BodyColor from '../../components/BodyColor'
import WorkTitleBlock from '../../components/WorkTitleBlock'

import styles from './yogam.module.scss'

export const frontmatter = {
  title: 'Yogam Site',
  type: 'Site and logo design',
  client: 'Personal',
  path: '/works/web/yogam/',
  date: '2013-11-10',
  image: 'yogam-demo',
  favorite: true,
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/yogam/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />
      <BodyColor isDark={true} background="#a1416d" />
      <div className={styles.page}>
      <h1 className="text-center">{frontmatter.title}</h1>
      <p className="aligncenter text-center">
        Design the site where people can find yoga classes in&nbsp;their town.
      </p>

      <figure className="text-center" style={{ marginBottom: '2em' }}>
        <Tablet
          fluid={findImage(images, 'yogam-home')}
          alt="Yogam home page"
          url="yogam.com.ua"
          shadow
          isDark
          style={{ maxWidth: 1000, margin: 'auto' }}
        />
      </figure>


      <p className="aligncenter text-center">
        Yoga studio owners register their business and own their home page. They
        create schedules, write trainers bio and upload photos and videos.
      </p>

      <figure className="text-center">
        <Preview
          fluid={findImage(images, 'yogam-studio')}
          alt="Studio page"
          url="Studio page"
          shadow
        />
      </figure>

      <figure className="text-center">
        <Preview
          fluid={findImage(images, 'yogam-schedule')}
          alt="Studio schedule"
          url="Studio schedule"
          shadow
        />
      </figure>

    
      <WorkTitleBlock {...frontmatter} />
      </div>

      <div className={styles.heroBg}>
          <Img fluid={findImage(images, 'alt-bg')} alt="" />
      </div>
    </Layout>
  )
}
