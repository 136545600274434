import React from 'react'
import PropTypes from 'prop-types'

import { withTheme } from '../Theme/withTheme'

import styles from './work-title-block.module.scss'

const TitleBlockModule = props => {
  const { label, value, className } = props

  const moduleClassName = [styles.module, className].join(' ')

  return (
    <dl className={moduleClassName}>
      <dt className={styles.label}>{label}</dt>
      <dd className={styles.value}>{value}</dd>
    </dl>
  )
}

const WorkTitleBlock = props => {
  const { title, client, date, type, themeContext } = props

  const dateObject = new Date(date)
  const formattedDate = dateObject.toLocaleDateString('en-US')

  const className = [
    styles.block,
    themeContext.isDark ? styles.dark : null,
  ].join(' ')

  return (
    <div className={styles.wrapper}>
      <div className={className}>
        <TitleBlockModule
          label="Project name"
          value={title}
          className={styles.name}
        />

        <TitleBlockModule
          label="Client"
          value={client}
          className={styles.client}
        />

        <TitleBlockModule
          label="Date"
          value={formattedDate}
          className={styles.date}
        />

        <TitleBlockModule
          label="Project type"
          value={type}
          className={styles.type}
        />
      </div>
    </div>
  )
}

WorkTitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  client: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string,
}

export default withTheme(WorkTitleBlock)
