import React from 'react'
import PropTypes from 'prop-types'
import styles from './preview.module.scss'
import Img from 'gatsby-image'
import Image from '../Image'

const Tablet = props => {
  const {
    isDark,
    shadow,
    src,
    url,
    fluid,
    alt,
    className,
    ...wrapperProps
  } = props

  const wrapperClassName =
    styles.tabletLandscape +
    (props.isDark ? ' ' + styles.dark : '') +
    (props.shadow ? ' ' + styles.tabletShadow : '')
  return (
    <div className={styles.tabletOuterWrap + ' ' + className} {...wrapperProps}>
      <div className={wrapperClassName}>
        <div className={styles.tabletWrap}>
          <div className={styles.image}>
            {fluid ? (
              <Img fluid={fluid} alt={alt} />
            ) : (
              <Image src={src} alt={alt} width={props.width} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Tablet.propTypes = {
  src: PropTypes.string,
  fluid: PropTypes.object,
  alt: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  isDark: PropTypes.bool,
  shadow: PropTypes.bool,
}

export default Tablet
