import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { withTheme } from '../Theme/withTheme'
import styles from './body-color.module.scss'

const BodyColor = props => {
  const { isDark, className, background } = props
  if (isDark || className || background) {
    if (isDark) {
      props.themeContext.setTheme(isDark)
    }

    if (background) {
      props.themeContext.setBackground(background)
    }

    const bodyClassName =
      (isDark ? styles.dark : '') + ' ' + (className ? className : '')

    // TODO: get rid of this in favour of seetting it to the body
    const bodyStyle = background ? (
      <style type="text/css">
        {`
    body {
      background: ` +
          background +
          ` !important;
    }
`}
      </style>
    ) : null

    const htmlStyle = isDark ? (
      <style type="text/css">
        {`
    html {
      background: ` + ( background? background: '#232323' ) + `;
    }
`}
      </style>
    ) : null

    return (
      <Helmet>
        <body className={bodyClassName} />
        {bodyStyle}
        {htmlStyle}
      </Helmet>
    )
  } else {
    return null
  }
}

BodyColor.propTypes = {
  isDark: PropTypes.bool,
  background: PropTypes.string,
  className: PropTypes.string,
}

export default withTheme(BodyColor)
