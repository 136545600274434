import React from 'react'
import PropTypes from 'prop-types'
import styles from './preview.module.scss'
import Img from 'gatsby-image'
import Image from '../Image'

// Browser Window Preview

const Preview = props => {
  const {
    isDark,
    isSmall,
    shadow,
    src,
    url,
    fluid,
    alt,
    className,
    ...wrapperProps
  } = props

  const wrapperClassName =
    (className ? className : '') +
    ' ' +
    styles.browser +
    (isDark ? ' ' + styles.dark : '') +
    (isSmall ? ' ' + styles.browserSmall : '')

  return (
    <div className={wrapperClassName} {...wrapperProps}>
      <div className={styles.browserBar}>
        <span>{url}</span>
      </div>
      <div className={styles.browserContent}>
        {props.fluid ? (
          <Img fluid={fluid} alt={alt} />
        ) : (
          <Image
            src={src}
            alt={alt}
            width={props.width}
            height={props.height}
          />
        )}
      </div>
    </div>
  )
}

Preview.propTypes = {
  src: PropTypes.string,
  fluid: PropTypes.object,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  isDark: PropTypes.bool,
  isSmall: PropTypes.bool,
  shadow: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default Preview
